/* styles.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home {
    text-align: center;
}

h1 {
    margin-bottom: 20px;
}

svg {
    width: 60%;
    height: auto;
    margin-bottom: 20px;
}